import { Auth0Resource } from "../models/auth0-resource";
import { PageFooterHyperlink } from "./page-footer-hyperlink";

export const PageFooter = () => {
  const resourceListLeft: Auth0Resource[] = [
    {
      path: "/mot-du-president",
      label: "Mot du président",
    },
    {
      path: "/bureau-amicale",
      label: "Bureau de l'amicale",
    },
    {
      path: "/statuts",
      label: "Statuts de l'amicale",
    },
  ];

  const resourceListMiddle: Auth0Resource[] = [
    {
      path: "/sponsors-partenaires",
      label: "Sponsors et partenaires",
    },
    {
      path: "/contact",
      label: "Contacter le bureau",
    },
  ];

  const resourceListRight: Auth0Resource[] = [
    
    {
      path: "/agenda",
      label: "Agenda",
    },
    {
      path: "/nominations",
      label: "Nominations",
    },
    {
      path: "/presse",
      label: "Presse",
    },
  ];

  const isActivePath = (path: string) => {
    return window.location.pathname === path;
  };

  return (
    <footer className="page-footer">
      <div className="page-footer-grid">
        <div className="page-footer-grid__info">
          <div className="page-footer-info__resource-list">
            {resourceListLeft.map((resource) => (
              <div
                key={resource.path}
                className={`page-footer-info__resource-list-item ${
                  isActivePath(resource.path) ? 'active' : ''
                }`}
              >
                <PageFooterHyperlink path={resource.path}>
                  <>{resource.label}</>
                </PageFooterHyperlink>
              </div>
            ))}
          </div>
          <div className="page-footer-info__resource-list">
            {resourceListMiddle.map((resource) => (
              <div
                key={resource.path}
                className={`page-footer-info__resource-list-item ${
                  isActivePath(resource.path) ? 'active' : ''
                }`}
              >
                <PageFooterHyperlink path={resource.path}>
                  <>{resource.label}</>
                </PageFooterHyperlink>
              </div>
            ))}
          </div>
          <div className="page-footer-info__resource-list">
            {resourceListRight.map((resource) => (
              <div
                key={resource.path}
                className={`page-footer-info__resource-list-item ${
                  isActivePath(resource.path) ? 'active' : ''
                }`}
              >
                <PageFooterHyperlink path={resource.path}>
                  <>{resource.label}</>
                </PageFooterHyperlink>
              </div>
            ))}
          </div>
        </div>
        <div className="page-footer-copyright">
          <span>©2024 Ponts Maroc</span>
          <span className="page-footer-copyright__separator">•</span>
          <a href="mailto:contact@pontsmaroc.ma" className="page-footer-copyright__email">
            contact@pontsmaroc.ma
          </a>
        </div>
      </div>
    </footer>
  );
};
