import React from "react";
import { NavLink } from "react-router-dom";

export const NavBarBrand: React.FC = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink to="/">
        <img
          className="nav-bar__logo"
          src="/images/logo.jpeg"
          alt="Company logo"
          width="100"
        />
      </NavLink>
    </div>
  );
};
