import React from "react";
import { NavBarBrand } from "./nav-bar-brand";
import { NavBarButtons } from "./nav-bar-buttons";
import { NavBarTabsLeft } from "./nav-bar-tabs1";
import { NavBarTabsRight } from "./nav-bar-tabs2";

export const NavBar: React.FC = () => {
  return (
    <div className="nav-bar__container">
      <nav className="nav-bar">
        <NavBarTabsLeft />
        <NavBarBrand />
        <NavBarTabsRight />
        <NavBarButtons />
      </nav>
    </div>
  );
};
