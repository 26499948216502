import React from "react";
import { NavLink } from "react-router-dom";

interface MobileNavBarTabProps {
  path: string;
  label: string;
  handleClick: () => void;
  disabled?: boolean;
}

export const MobileNavBarTab: React.FC<MobileNavBarTabProps> = ({
  path,
  label,
  handleClick,
  disabled,
}) => {
  return (
    <NavLink
      to={path}
      end
      className={({ isActive }) =>
        "mobile-nav-bar__tab " + (isActive ? "mobile-nav-bar__tab--active" : "") + (disabled ? " mobile-nav-bar__tab--disabled" : "")
      }
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
        } else {
          handleClick();
        }
      }}
      style={disabled ? { pointerEvents: "none", opacity: 0.5 } : undefined}
    >
      {label}
    </NavLink>
  );
};
