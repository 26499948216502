import { useEffect, useState } from "react";
import { ActiviteFull } from "../types/api-types";
import { useAuth0 } from "@auth0/auth0-react";
import { getActiviteById } from "../services/activites.service";

interface ArticleDetailProps {
  articleId: string;
}

export const ArticleDetail = ({ articleId }: ArticleDetailProps) => {
  const [article, setArticle] = useState<ActiviteFull | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        setLoading(true);
        const accessToken = await getAccessTokenSilently();
        const { data, error: apiError } = await getActiviteById(accessToken, articleId);
        console.log(data);
        if (apiError) {
          throw new Error(apiError.message);
        }

        setArticle(data as unknown as ActiviteFull);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [articleId, getAccessTokenSilently]);

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>Erreur: {error}</div>;
  if (!article) return <div>Article non trouvé</div>;

  return (
    <div className="article-detail">
      <h2>{article.title}</h2>
      <p className="article-date">{new Date(article.date).toLocaleDateString()}</p>
      <img 
        src={`${process.env.REACT_APP_API_SERVER_URL}${article.mainImage}`}
        alt={article.title} 
        className="article-main-image"
      />
      <div className="article-content" dangerouslySetInnerHTML={{ __html: article.content }} />
      
      {article.images && article.images.length > 0 && (
        <div className="article-gallery">
          <h3>Galerie d'images</h3>
          <div className="image-grid">
            {article.images.map((image, index) => (
              <img 
                key={index} 
                src={`${process.env.REACT_APP_API_SERVER_URL}${image}`} 
                alt={`${article.title}`} 
                className="gallery-image"
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};