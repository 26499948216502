import { PageLayout } from "../components/page-layout";

export const PresentationPage = () => {
  return (
    <PageLayout>
      <div className="content-page bg-logo">
        <h1>Qui sommes-nous ?</h1>
        <p>
          L'Amicale des Ingénieurs des Ponts et Chaussées du Maroc « Ponts Maroc »
          réunit les anciens élèves marocains ayant obtenu le diplôme d'ingénieur de
          l'Ecole Nationale des Ponts et Chaussées. Elle est aujourd'hui l'une
          des plus importantes associations d'anciens élèves des grandes écoles
          françaises et compte environ 500 membres. Ponts Maroc organise différents
          évènements parmi lesquels des conférences thématiques, des colloques et
          des visites de sites. L'orientation générale est de contribuer au
          développement du pays.
        </p>
        <p>
          L'association s'est enrichie au cours des années de la diversité des profils et des parcours et continue de se réformer avec l'arrivée de ses jeunes membres. Forte de ces profils d'excellence qui se sont orientés tant vers le secteur privé que vers le secteur public et la gouvernance, l'association Ponts Maroc aspire, à travers ses actions, à renforcer les liens entre nouveaux et anciens diplômés.
        </p>
      </div>
    </PageLayout>
  );
};
