import { PageLayout } from "../components/page-layout";
import { useState, useEffect } from "react";
import { Camarade } from "../types/api-types";
import { useAuth0 } from "@auth0/auth0-react";
import { getCamarades } from "../services/annuaire.service";
import { CamaradeListResponse } from "../models/api-response";


export const AnnuairePage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [members, setMembers] = useState<Camarade[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalMembers, setTotalMembers] = useState(0);
  const itemsPerPage = 30;

  useEffect(() => {
    const fetchMembers = async () => {
      setIsLoading(true);
      try {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getCamarades(
          accessToken,
          currentPage * itemsPerPage,
          itemsPerPage
        );
        
        if (error || !data) {
          console.error(error);
          return;
        }

        const camarades = (data as unknown as CamaradeListResponse).data as Camarade[];
        const totalMembers = (data as unknown as CamaradeListResponse).total;

        if (camarades) {
          setMembers(camarades);
          setTotalMembers(totalMembers);
        } else {
          setMembers([]);
          setTotalMembers(0);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMembers();
  }, [getAccessTokenSilently, currentPage]);

  const filteredMembers = members.filter((member) =>
    Object.values(member).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const totalPages = Math.ceil(totalMembers / itemsPerPage);

  return (
    <PageLayout>
      <div className="grid-layout__content">
        <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold mb-6">Notre Annuaire</h1>
          
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <p>Chargement...</p>
            </div>
          ) : (
            <>
              <div className="mb-6">
                <input
                  type="text"
                  placeholder="Rechercher un membre..."
                  className="search-input"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              <div className="members-grid gap-6 mb-8">
                {filteredMembers.length === 0 ? (
                  <p>Pas de membres enregistrés</p>
                ) : (
                  filteredMembers.map((member) => (
                    <div 
                      key={member.id} 
                      className="member-card"
                    >
                      <div className="member-info">
                        <h3>{member.name}</h3>
                        <div className="member-details">
                          <p>Promotion {member.promotionYear}</p>
                          <p>{member.currentSector}</p>
                          <p>{member.currentPosition}</p>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>

              {/* Pagination Controls */}
              <div className="pagination-controls">
                <button
                  onClick={() => setCurrentPage(p => Math.max(0, p - 1))}
                  disabled={currentPage === 0}
                  className="pagination-button"
                >
                  Précédent
                </button>
                <span className="pagination-info">
                  Page {currentPage + 1} sur {totalPages}
                </span>
                <button
                  onClick={() => setCurrentPage(p => Math.min(totalPages - 1, p + 1))}
                  disabled={currentPage >= totalPages - 1}
                  className="pagination-button"
                >
                  Suivant
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </PageLayout>
  );
};
