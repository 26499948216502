import { useAuth0 } from "@auth0/auth0-react";

export const useAuthRoles = () => {
  const { isAuthenticated, user } = useAuth0();
  const hasCamaradeRole = user?.['https://myapp.com/roles']?.includes('camarade');
  const canAccessCamaradeRoutes = isAuthenticated && hasCamaradeRole;
  const hasAdminRole = user?.email?.includes('@dspin.ma');
  const canAccessAdminRoutes = isAuthenticated && hasAdminRole;

  return {
    isAuthenticated,
    canAccessCamaradeRoutes,
    canAccessAdminRoutes
  };
};