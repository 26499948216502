import { PageLayout } from "../components/page-layout";
import { useState, useEffect } from "react";
import { Nomination } from "../types/api-types";
import { getNominations } from "../services/nomination.service";
import { useAuth0 } from "@auth0/auth0-react";

export const NominationsPage = () => {
  const [nominations, setNominations] = useState<Nomination[]>([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchNominations = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getNominations(accessToken);
        
        if (error) {
          console.error('Error fetching nominations:', error);
          return;
        }

        setNominations(data as unknown as Nomination[]);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchNominations();
  }, [getAccessTokenSilently]);

  return (
    <PageLayout>
      <div className="content-page">
        <h1>Dernières nominations</h1>
        <div className="articles-grid">
            {nominations.map((nomination) => (
                <article 
                key={nomination.id} 
                className="article-card"
                >
                {nomination.photo && (
                    <img
                        src={`${process.env.REACT_APP_API_SERVER_URL}${nomination.photo}`}
                        alt={nomination.name}
                        className="article-image"
                    />
                )}
                <div className="article-content">
                    <h2>{nomination.name}</h2>
                    <h3>Promotion {nomination.promotion}</h3>
                    <h3>{nomination.position}</h3>
                    <p>{nomination.description}</p>
                </div>
                </article>
            ))}
        </div>
      </div>
    </PageLayout>
  );
};
