import { PageLayout } from "../components/page-layout";

export const StatutsPage = () => {
  return (
    <PageLayout>
      <div className="content-page bg-logo">
        <h1>Statuts de l'Amicale (A CHANGER)</h1>
        
        <p>
        <h2>TITRE I : CONSTITUTION ET OBJET</h2>
        
        <h3>Article 1 : Formation</h3>
        Il est formé entre les anciens élèves de l'École Nationale Supérieure d'Informatique et d'Analyse des Systèmes (ENSIAS) une association dénommée "Amicale des Anciens de l'ENSIAS".

        <h3>Article 2 : Objectifs</h3>
        L'Amicale a pour buts de :
        <ul>
          <li>Maintenir et développer des relations amicales entre les anciens élèves</li>
          <li>Promouvoir l'entraide professionnelle entre ses membres</li>
          <li>Contribuer au rayonnement de l'ENSIAS</li>
          <li>Participer à l'insertion professionnelle des nouveaux diplômés</li>
        </ul>

        <h2>TITRE II : MEMBRES ET COTISATIONS</h2>

        <h3>Article 3 : Composition</h3>
        L'Amicale se compose de :
        <ul>
          <li>Membres actifs : Anciens élèves diplômés de l'ENSIAS</li>
          <li>Membres d'honneur : Personnalités ayant rendu des services signalés à l'association</li>
          <li>Membres bienfaiteurs : Personnes physiques ou morales soutenant financièrement l'association</li>
        </ul>

        <h3>Article 4 : Cotisations</h3>
        La cotisation annuelle est fixée par le Bureau de l'Amicale. Elle peut être révisée lors de l'Assemblée Générale ordinaire.

        <h2>TITRE III : ADMINISTRATION</h2>

        <h3>Article 5 : Le Bureau</h3>
        L'Amicale est administrée par un Bureau composé de :
        <ul>
          <li>Un Président</li>
          <li>Un Vice-président</li>
          <li>Un Secrétaire Général</li>
          <li>Un Trésorier</li>
          <li>Des Assesseurs</li>
        </ul>

        <h3>Article 6 : Assemblées Générales</h3>
        L'Assemblée Générale ordinaire se réunit une fois par an. Elle entend les rapports sur la gestion du Bureau et sur la situation financière et morale de l'Amicale.

        <h2>TITRE IV : DISPOSITIONS DIVERSES</h2>

        <h3>Article 7 : Modification des Statuts</h3>
        Les présents statuts ne peuvent être modifiés que sur proposition du Bureau ou du quart des membres actifs, et après approbation de l'Assemblée Générale extraordinaire.

        <h3>Article 8 : Dissolution</h3>
        La dissolution de l'Amicale ne peut être prononcée que par l'Assemblée Générale extraordinaire convoquée spécialement à cet effet.
        </p>
      </div>
    </PageLayout>
  );
};
