import React from "react";
import { NavLink } from "react-router-dom";

interface NavBarTabProps {
  path: string;
  label: string;
  disabled?: boolean;
}

export const NavBarTab: React.FC<NavBarTabProps> = ({ path, label, disabled = false }) => {
  return (
    <NavLink
      to={path}
      end
      className={({ isActive }) =>
        "nav-bar__tab " + 
        (isActive ? "nav-bar__tab--active" : "") +
        (disabled ? " nav-bar__tab--disabled" : "")
      }
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
        }
      }}
      style={disabled ? { pointerEvents: 'none', opacity: 0.5 } : undefined}
    >
      {label}
    </NavLink>
  );
};
