import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Camarade } from "../../types/api-types";
import { createCamarade, getCamarades, deleteCamarade } from "../../services/annuaire.service";

export const AdminAnnuaire: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [camarades, setCamarades] = useState<Camarade[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    promotionYear: '',
    currentSector: '',
    currentPosition: ''
  });

  useEffect(() => {
    const fetchCamarades = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getCamarades(accessToken);
        if (!error && data) {
          setCamarades(data as unknown as Camarade[]);
        }
      } catch (error) {
        console.error('Error fetching camarades:', error);
      }
    };

    fetchCamarades();
  }, [getAccessTokenSilently]);

  const handleDelete = async (id: string) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const { error } = await deleteCamarade(accessToken, id);
      
      if (!error) {
        // Remove the deleted camarade from the state
        setCamarades(prev => prev.filter(camarade => camarade.id !== id));
      } else {
        console.error('Error deleting camarade:', error);
      }
    } catch (error) {
      console.error('Error deleting camarade:', error);
    }
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const accessToken = await getAccessTokenSilently();
      const formDataObj = new FormData();
      formDataObj.append('name', formData.name);
      formDataObj.append('promotion_year', formData.promotionYear);
      formDataObj.append('current_sector', formData.currentSector);
      formDataObj.append('current_position', formData.currentPosition);

      const camaradeData = {
        name: formData.name,
        promotionYear: formData.promotionYear,
        currentSector: formData.currentSector,
        currentPosition: formData.currentPosition
      };

      const { data, error } = await createCamarade(accessToken, camaradeData);
      if (!error && data) {
        setCamarades(prev => [...prev, data as unknown as Camarade]);
        setShowForm(false);
      }
    } catch (error) {
      console.error('Error saving camarade:', error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="tab-pane">
      <h2>Gestion de l'Annuaire</h2>
      <button className="add-button" onClick={() => setShowForm(true)}>
        + Ajouter un camarade
      </button>
      
      {showForm && (
        <form className="admin-form" onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="name">Nom</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="promotionYear">Année de promotion</label>
            <input
              type="text"
              id="promotionYear"
              name="promotionYear"
              value={formData.promotionYear}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="currentSector">Secteur actuel</label>
            <input
              type="text"
              id="currentSector"
              name="currentSector"
              value={formData.currentSector}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="currentPosition">Poste actuel</label>
            <input
              type="text"
              id="currentPosition"
              name="currentPosition"
              value={formData.currentPosition}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-actions">
            <button type="submit" className="submit-button">
              Sauvegarder
            </button>
            <button 
              type="button" 
              className="cancel-button"
              onClick={() => setShowForm(false)}
            >
              Annuler
            </button>
          </div>
        </form>
      )}

      <div className="items-list">
        {camarades.map(camarade => (
          <div key={camarade.id} className="admin-item">
            <h3>{camarade.name}</h3>
            <p className="camarade-details">
              Promotion {camarade.promotionYear}
            </p>
            <p className="camarade-position">
              {camarade.currentPosition} - {camarade.currentSector}
            </p>
            <button 
              className="delete-button"
              onClick={() => handleDelete(camarade.id)}
            >
              Supprimer
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}; 