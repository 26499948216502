import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { Route, Routes } from "react-router-dom";
import { AdminPage } from "./pages/admin-page";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";

import { PresentationPage } from "./content/presentation";
import { MotDuPresidentPage } from "./content/mot-president";
import { BureauAmicalePage } from "./content/bureau-amicale";
import { SponsorsPartenairesPage } from "./content/sponsors-partenaires";
import { ContactPage } from "./content/contact";
import { StatutsPage } from "./content/statuts";
import { AnnuairePage } from "./content/annuaire";
import { NominationsPage } from "./content/nominations";
import { ActualitesPage } from "./content/actualites";
import { PressePage } from "./content/presse";
import { AgendaPage } from "./content/agenda";
import { CamaradesPage } from "./content/camarades";
import { ClubPage } from "./content/club";

export const App: React.FC = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <Routes>
      
      <Route path="/" element={<PresentationPage />} />
      <Route path="/presentation" element={<PresentationPage />} />
      <Route path="/actualites" element={<ActualitesPage />} />

      
      <Route path="/mot-du-president" element={<MotDuPresidentPage />} />
      <Route path="/bureau-amicale" element={<BureauAmicalePage />} />
      <Route path="/sponsors-partenaires" element={<SponsorsPartenairesPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/statuts" element={<StatutsPage />} />
      <Route path="/annuaire" element={<AnnuairePage />} />
      <Route path="/nominations" element={<NominationsPage />} />
      <Route path="/presse" element={<PressePage />} />
      <Route path="/agenda" element={<AgendaPage />} />
      <Route path="/camarades" element={<CamaradesPage />} />
      <Route path="/club" element={<ClubPage />} />

      <Route
        path="/admin"
        element={<AuthenticationGuard component={AdminPage} />}
      />
      <Route path="/callback" element={<HomePage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
