import { PageLayout } from "../components/page-layout";
import { useAuthRoles } from "../hooks/use-auth-roles";


export const ClubPage = () => {
  const { canAccessCamaradeRoutes } = useAuthRoles();

  return (
    <PageLayout>
      <>
        <div className="content-page">ClubPage</div>
        { canAccessCamaradeRoutes && <div>Vous avez accès à cette page</div>}
        { !canAccessCamaradeRoutes && <div>Vous n'avez pas accès à cette page</div>}
      </>
    </PageLayout>
  );
};
