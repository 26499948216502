import { PageLayout } from "../components/page-layout";

export const SponsorsPartenairesPage = () => {
  return (
    <PageLayout>
      <div className="content-page">
        <h1>Nos Sponsors et Partenaires</h1>
        
        <h2>Sponsors Principaux</h2>
        <div className="sponsors-grid">
          <div className="sponsor-card">
            <img src="https://picsum.photos/200/100" alt="Logo Sponsor 1" />
            <h3>Entreprise Construction SA</h3>
            <p>Leader dans le secteur du BTP au Maroc</p>
          </div>
          <div className="sponsor-card">
            <img src="https://picsum.photos/200/101" alt="Logo Sponsor 2" />
            <h3>Infrastructure & Co</h3>
            <p>Spécialiste des grands projets d'infrastructure</p>
          </div>
          <div className="sponsor-card">
            <img src="https://picsum.photos/200/102" alt="Logo Sponsor 3" />
            <h3>Bureau d'Études XYZ</h3>
            <p>Excellence en ingénierie depuis 1980</p>
          </div>
        </div>

        <h2>Partenaires Institutionnels</h2>
        <div className="partners-section">
          <div className="partner-card">
            <img src="https://picsum.photos/200/103" alt="Logo Partenaire 1" />
            <h3>École des Ponts ParisTech</h3>
            <p>Notre alma mater et partenaire historique</p>
          </div>
          <div className="partner-card">
            <img src="https://picsum.photos/200/104" alt="Logo Partenaire 2" />
            <h3>Ministère de l'Équipement</h3>
            <p>Collaboration étroite sur les projets nationaux</p>
          </div>
          <div className="partner-card">
            <img src="https://picsum.photos/200/105" alt="Logo Partenaire 3" />
            <h3>Association des Ingénieurs Marocains</h3>
            <p>Partenaire pour le développement professionnel</p>
          </div>
        </div>

        <p className="support-text">
          Nous remercions chaleureusement tous nos sponsors et partenaires pour leur soutien continu 
          dans notre mission de promotion de l'excellence en ingénierie au Maroc.
        </p>
      </div>
    </PageLayout>
  );
};
