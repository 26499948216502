import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { MobileNavBarTab } from "./mobile-nav-bar-tab";
import { useAuthRoles } from "../../../hooks/use-auth-roles";

interface MobileNavBarTabsProps {
  handleClick: () => void;
}

export const MobileNavBarTabs: React.FC<MobileNavBarTabsProps> = ({
  handleClick,
}) => {
  const { canAccessCamaradeRoutes } = useAuthRoles();
  const { isAuthenticated } = useAuth0();

  return (
    <div className="mobile-nav-bar__tabs">
      <MobileNavBarTab
        path="/presentation"
        label="Présentation"
        handleClick={handleClick}
      />
      <MobileNavBarTab
        path="/actualites"
        label="Actualités"
        handleClick={handleClick}
      />
      {isAuthenticated && (
        <>
          <MobileNavBarTab
            path="/admin"
            label="Administration"
            handleClick={handleClick}
          />
          <MobileNavBarTab
            path="/camarades"
            label="Camarades"
            handleClick={handleClick}
            disabled={!canAccessCamaradeRoutes}
          />
          <MobileNavBarTab
            path="/club"
            label="Club"
            handleClick={handleClick}
            disabled={!canAccessCamaradeRoutes}
          />
        </>
      )}
    </div>
  );
};
