import { PageLayout } from "../components/page-layout";

export const ContactPage = () => {
  return (
    <PageLayout>
      <div className="content-page bg-logo">
        <h1>Contacter le Bureau</h1>
        
        <p>
          Pour toute question ou demande d'information, n'hésitez pas à contacter le bureau de l'amicale :
        </p>

        <p>
          <strong>Email :</strong> <a href="mailto:contact@pontsmaroc.ma" className="underlined">contact@pontsmaroc.ma</a>
        </p>

        <p>
          <strong>Adresse :</strong><br />
          Association des Anciens Élèves des Ponts et Chaussées au Maroc<br />
          123 Avenue Hassan II<br />
          20000 Casablanca<br />
          Maroc
        </p>

        <p>
          <strong>Téléphone :</strong><br />
          +212 5 22 XX XX XX
        </p>

        <p>
          <strong>Horaires du secrétariat :</strong><br />
          Du lundi au vendredi<br />
          9h00 - 12h30<br />
          14h00 - 17h30
        </p>

        <p>
          Nous nous efforçons de répondre à toutes les demandes dans les meilleurs délais.
        </p>
      </div>
    </PageLayout>
  );
};
