import React, { useState } from "react";
import { PageLayout } from "../components/page-layout";
import { AdminActivites } from "../components/admin/admin-activites";
import { AdminAnnuaire } from "../components/admin/admin-annuaire";
import { AdminNominations } from "../components/admin/admin-nominations";
import { AdminPresse } from "../components/admin/admin-presse";
import { AdminJobs } from "../components/admin/admin-jobs";

export const AdminPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("actualites");

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Admin Page
        </h1>
        <div className="content__body">
          {/* Tabs */}
          <div className="tabs">
            <button 
              className={`tab ${activeTab === 'actualites' ? 'active' : ''}`}
              onClick={() => setActiveTab('actualites')}
            >
              Actualités
            </button>
            <button 
              className={`tab ${activeTab === 'annuaire' ? 'active' : ''}`}
              onClick={() => setActiveTab('annuaire')}
            >
              Annuaire
            </button>
            <button 
              className={`tab ${activeTab === 'nominations' ? 'active' : ''}`}
              onClick={() => setActiveTab('nominations')}
            >
              Nominations
            </button>
            <button 
              className={`tab ${activeTab === 'jobs' ? 'active' : ''}`}
              onClick={() => setActiveTab('jobs')}
            >
              Emplois
            </button>
          </div>

          {/* Tab Content */}
          <div className="tab-content">
            {activeTab === 'actualites' && <AdminActivites />}
            {activeTab === 'annuaire' && <AdminAnnuaire />}
            {activeTab === 'nominations' && <AdminNominations />}
            {activeTab === 'presse' && <AdminPresse />}
            {activeTab === 'jobs' && <AdminJobs />}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
