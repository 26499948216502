import { PageLayout } from "../components/page-layout";

export const MotDuPresidentPage = () => {
  return (
    <PageLayout>
      <>
        <div className="content-page bg-logo">
          <img
            src="/images/Photo-Ponts-Maroc-e1699005458597.jpg"
            alt="Mohamed Amine Souissi"
            style={{ width: '100%', maxWidth: '100%' }}
          />
          <h1>Mot du président</h1>

          <p>Chers Camarades,</p>

          <p>
            C'est avec un immense honneur et une profonde gratitude que je
            m'adresse à vous en tant que président de notre Amicale des
            Ingénieurs des Ponts et Chaussées du Maroc « Ponts Maroc ».
          </p>

          <p>
            Notre amicale n'est pas seulement une communauté d'anciens élèves,
            mais un réseau fort et solidaire de professionnels unis par des
            valeurs communes : l'excellence, l'engagement et la volonté de
            contribuer activement au développement de notre pays.
          </p>

          <p>
            Depuis sa création, notre amicale a évolué et s'est enrichie de la
            diversité des parcours de ses membres, tant dans le secteur public
            que privé. Grâce à cette richesse humaine, nous avons su bâtir des
            ponts entre générations, secteurs d'activité et régions. Nos actions
            s'inscrivent dans une dynamique d'échange et de collaboration, avec
            pour objectif de soutenir l'innovation, de promouvoir le progrès et
            de renforcer les liens qui nous unissent.
          </p>

          <p>
            Aujourd'hui, avec plus de 500 membres, Ponts Maroc continue de
            grandir et de se réinventer. Nos événements (conférences, colloques,
            visites de sites, voyages…) témoignent de notre engagement à rester
            au cœur des débats et des projets qui façonnent l'avenir du Maroc.
            Mais au-delà de nos réalisations collectives, ce sont les liens
            d'amitié et de solidarité tissés entre nos membres qui font la force
            de notre amicale.
          </p>

          <p>
            Je tiens à remercier chacun d'entre vous pour votre participation
            active, votre dynamisme et votre engagement constant. Ensemble, nous
            continuerons à renforcer ce réseau d'excellence, à inspirer les
            nouvelles générations et à contribuer à l'édification d'un avenir
            prometteur pour notre pays.
          </p>

          <p>Bâtissons ensemble un pont vers l'avenir.</p>

          <p>
            <b>Mohamed Amine Souissi</b>
            <br />
            <i>
              Président de l'Amicale des Ingénieurs des Ponts et Chaussées du
              Maroc "Ponts Maroc"
            </i>
          </p>
        </div>
      </>
    </PageLayout>
  );
};
