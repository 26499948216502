import React from "react";
import { NavBarTab } from "./nav-bar-tab";
import { useAuthRoles } from "../../../hooks/use-auth-roles";

export const NavBarTabsRight: React.FC = () => {
  const { canAccessCamaradeRoutes } = useAuthRoles();

  return (
    <div className="nav-bar__tabs-right">
      <NavBarTab path="/annuaire" label="Annuaire" />
      <NavBarTab path="/camarades" label="Espace camarades" />
      <NavBarTab 
        path="/club" 
        label="Club" 
        disabled={!canAccessCamaradeRoutes}
      />
    </div>
  );
};
