import React from "react";
import { Link } from "react-router-dom";

interface FooterHyperlinkProps {
  children: JSX.Element;
  path: string;
}

export const PageFooterHyperlink: React.FC<FooterHyperlinkProps> = ({
  children,
  path,
}) => {
  return (
    <Link
      className="page-footer__hyperlink"
      to={path}
    >
      {children}
    </Link>
  );
};
