import { useAuthRoles } from "../../../hooks/use-auth-roles";
import React from "react";
import { NavBarTab } from "./nav-bar-tab";

export const NavBarTabsLeft: React.FC = () => {
  const { canAccessAdminRoutes } = useAuthRoles();
  return (
    <div className="nav-bar__tabs">
      <NavBarTab path="/presentation" label="Présentation" />
      <NavBarTab path="/actualites" label="Actualités" />
      {canAccessAdminRoutes && <NavBarTab path="/admin" label="Administration" />}
    </div>
  );
};
