import React from 'react';

export const MainPage: React.FC = () => {
  return (
    <div className="main-page">
      <header className="header">
        <h1>Welcome to Our App</h1>
        <nav>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </nav>
      </header>

      <main className="content">
        <section className="hero">
          <h2>Your Main Content Here</h2>
          <p>This is where your primary content will go.</p>
        </section>

        <section className="features">
          <div className="feature">
            <h3>Feature 1</h3>
            <p>Description of feature 1</p>
          </div>
          <div className="feature">
            <h3>Feature 2</h3>
            <p>Description of feature 2</p>
          </div>
          <div className="feature">
            <h3>Feature 3</h3>
            <p>Description of feature 3</p>
          </div>
        </section>
      </main>
    </div>
  );
};
