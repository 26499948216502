import { PageLayout } from "../components/page-layout";
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getJobs } from "../services/jobs.service";
import { Job } from "../types/api-types";

export const CamaradesPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [jobs, setJobs] = useState<Job[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchJobs = async () => {
      setIsLoading(true);
      try {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getJobs(accessToken);
        
        if (error || !data) {
          console.error(error);
          return;
        }

        setJobs(data as unknown as Job[]);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchJobs();
  }, [getAccessTokenSilently]);

  return (
    <PageLayout>
      <div className="grid-layout__content">
        <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <>
            <h1 className="text-4xl font-bold mb-6">Offres d'emploi</h1>
            {isLoading ? (
              <div className="flex justify-center items-center h-64">
                <p>Chargement...</p>
              </div>
            ) : (
              <div className="space-y-6">
                {jobs.length === 0 ? (
                  <p>Pas d'offres d'emploi disponibles</p>
                ) : (
                  jobs.map((job) => (
                    <div 
                      key={job.id} 
                      className="bg-white shadow rounded-lg p-6"
                    >
                      <h3 className="text-xl font-semibold mb-2">{job.title}</h3>
                      <div className="space-y-2">
                        <p className="text-gray-600">{job.description}</p>
                        <div className="text-sm text-gray-500">
                          <p>Contact: {job.camaradeReferent}</p>
                          <p>Date de publication: {new Date(job.datePublication).toLocaleDateString()}</p>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </>
        </div>
      </div>
    </PageLayout>
  );
};
